import React from "react";
import classnames from "classnames";
import { Outlet, useLoaderData } from "@remix-run/react";
import Checklist from "~/UI/3-organisms/Checklist";
import SidebarProfileInfo from "~/UI/3-organisms/SidebarProfileInfo";
import { useLocation } from "react-router";
import type { ApolloQueryResult } from "@apollo/client";
import type {
  Therapist2,
  TherapistProfileAuthQuery,
} from "~/generated/graphql";
import client from "~/lib/graphql-client";
import { createHeaders } from "~/services/session.server";
import type { LoaderFunction } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import gql from "graphql-tag";
import * as url from "url";
import { useTranslation } from "react-i18next";
import renderChecklistItems from "~/UI/3-organisms/Checklist/renderChecklistItems";
import { buildUrl } from "cloudinary-build-url";

export const serverErrors = [
  "NotAuthenticatedError",
  "NotATherapistError",
  "DoesntExistError",
];

const CheckAuth = gql`
  query TherapistProfileAuth {
    me {
      therapist {
        id
        firstName
        middleName
        lastName
        honorific {
          id
          title
        }
        slug
        status
        internship {
          title {
            id
            name
            abbreviation
          }
          state {
            id
            name
            code
            tz
            licensures {
              id
              name
              categoryPageExcerpt
            }
            categoryPageExcerpt
          }
          registrationNumber
          workPlaceName
          supervisorFirstName
          supervisorLastName
          id
          supervisorLicense {
            licensure {
              id
              name
              categoryPageExcerpt
            }
            otherLicensureText
            issueState {
              id
              name
              code
              tz
            }
            licenseNumber
            validUntil
          }
        }
        primaryHeadshot {
          url
          publicId
          version
        }
        licenses {
          id
          licensure {
            id
            abbreviation
            name
          }
          otherLicensureText
          issueState {
            id
            name
          }
          licenseNumber
          validUntil
        }
      }
      errors {
        __typename
        ... on BaseError {
          message
        }
      }
    }
  }
`;
export const buildCloudinaryUrl = (
  cloudName: string,
  publicId?: string,
  version?: string | null
) => {
  if (version && publicId) {
    return buildUrl(`v${version}/${publicId}`, {
      cloud: { cloudName },
      transformations: {
        gravity: "faces",
        width: 493,
        height: 448,
        crop: "fill",
      },
    });
  }
  return null;
};
export const loader: LoaderFunction = async ({ request }) => {
  const res: ApolloQueryResult<TherapistProfileAuthQuery> = await client.query({
    query: CheckAuth,
    fetchPolicy: "network-only",
    context: { headers: await createHeaders(request) },
  });
  const { me } = res.data;

  if (me.errors?.find((error) => serverErrors.includes(error.__typename))) {
    throw redirect("/login");
  }
  if (!me.therapist) {
    throw redirect("/login");
  }
  const pathname = new url.URL(request.url).pathname;
  if (pathname === "/dashboard" || pathname === "/dashboard/") {
    throw redirect("/dashboard/general");
  }
  const primaryHeadshot =
    buildCloudinaryUrl(
      `${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD}`,
      me.therapist.primaryHeadshot?.publicId,
      me.therapist.primaryHeadshot?.version
    ) ||
    me.therapist.primaryHeadshot?.url ||
    null;

  const fullName = `${
    me.therapist?.honorific ? `${me.therapist?.honorific.title} ` : ""
  } ${me.therapist?.firstName} ${me.therapist?.middleName} ${
    me.therapist?.lastName
  }`;
  const licenses =
    me.therapist?.licenses.map(
      (license) =>
        ` ${license.licensure?.abbreviation || license.otherLicensureText}`
    ) || [];
  const profileLink = `${process.env.DIRECTORY_DOMAIN}/therapist/${
    (me.therapist as Therapist2).slug
  }`;
  const status = me.therapist?.status;
  return json({
    fullName,
    licenses,
    profileLink,
    status,
    primaryHeadshot,
    internship: Boolean(me.therapist.internship),
  });
};

export default function Index() {
  const location = useLocation();
  let { t: tGeneral } = useTranslation("general");
  const data = useLoaderData();
  const {
    fullName,
    licenses,
    profileLink,
    status,
    primaryHeadshot,
    internship,
  } = useLoaderData();

  const isEmptyBothLicenseAndInternship = !internship && !licenses?.length;
  const isFilledInternshipAndEmptyLicenses = internship && !licenses?.length;
  const showInternship =
    isFilledInternshipAndEmptyLicenses || isEmptyBothLicenseAndInternship;

  return (
    <div className={"mx-auto flex desktop:space-x-10"}>
      <aside
        className={classnames(
          "mt-11 hidden",
          "desktop:col-start-1 desktop:block desktop:max-w-[15.3125rem]"
        )}
      >
        <div
          data-testid={"desktop-sidebar"}
          className={"desktop:sticky desktop:top-11 desktop:mb-25"}
        >
          <SidebarProfileInfo
            fullName={fullName}
            status={status}
            licenses={licenses}
            profileLink={profileLink}
            primaryHeadshot={primaryHeadshot}
          />
          <Checklist {...renderChecklistItems([], location, showInternship)} />
        </div>
      </aside>
      <main
        className={classnames(
          "small-mobile:px-4",
          "box-border w-full px-4 pt-5",
          "tablet:px-6",
          "laptop:max-w-[73.75rem] laptop:px-5 laptop:pt-8",
          "desktop:w-[53.75rem] desktop:px-0 desktop:pt-11 "
        )}
      >
        <div
          data-testid="mobile-tablet-sidebar-info"
          className="desktop:hidden"
        >
          <SidebarProfileInfo
            fullName={fullName}
            status={status}
            licenses={licenses}
            profileLink={profileLink}
            primaryHeadshot={primaryHeadshot}
          />
        </div>
        <Outlet />
      </main>
    </div>
  );
}
