import React from "react";
import classnames from "classnames";
import { Img } from "../../1-atoms/Icons/52 ";

interface FileInputImageProps {
  url: string;
  fileName: string;
  showPlaceholder: boolean;
  altText: string;
  sidebar?: boolean;
}

const FileInputImage: React.FC<
  FileInputImageProps & React.AllHTMLAttributes<HTMLDivElement>
> = ({
  url,
  fileName,
  showPlaceholder,
  className,
  altText = "",
  sidebar = false,
  ...props
}) => {
  return (
    <>
      {showPlaceholder && !url && (
        <div
          data-testid={sidebar ? "sidebar-img-placeholder" : "img-placeholder"}
          className={classnames(
            "relative border border-gray200 bg-gray200 ",
            // z-10
            className
          )}
        >
          <Img
            className={classnames(
              "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[45px] text-gray350",
              { "-translate-y-[25px]": sidebar }
            )}
          />
        </div>
      )}
      {!!url && (
        <div
          {...props}
          className={classnames("z-10 overflow-hidden", className)}
        >
          <img
            data-testid={sidebar ? "sidebar-img" : "img"}
            className={classnames("h-full w-full object-cover")}
            src={url}
            alt={altText ? altText : fileName}
          />
        </div>
      )}
    </>
  );
};

export default FileInputImage;
