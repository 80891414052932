import React from "react";
import { CheckPinkEmpty, Point } from "../../1-atoms/Icons/16 ";

interface CheckListItemProps {
  filledInfo: boolean;
}

const CheckListItem: React.FC<
  CheckListItemProps & React.AllHTMLAttributes<HTMLLIElement>
> = ({ filledInfo, children, ...props }) => {
  return (
    <li
      className="typography-base-bold typography-base-bold flex list-none items-center text-black050"
      {...props}
    >
      {filledInfo && (
        <Point
          data-testid={"filled-item-icon"}
          className={"mr-2 inline-block"}
        />
      )}
      {!filledInfo && (
        <CheckPinkEmpty
          data-testid={"unfilled-item-icon"}
          className={"mr-2 inline-block"}
        />
      )}
      {children}
    </li>
  );
};

export default CheckListItem;
