import React from "react";
import CheckListItem from "../../2-molecules/Checklistitem";
import { useLocation } from "react-router";

export interface ChecklistProps {
  items: JSX.Element[] | string[];
  checkedItems: number[];
}

function Checklist({
  items = [],
  checkedItems = [],
  className,
  ...props
}: ChecklistProps & React.HTMLAttributes<HTMLUListElement>) {
  const location = useLocation();
  return (
    <nav {...props}>
      <ul className={"space-y-5"}>
        {items.map((item, ix) => (
          <CheckListItem
            data-testid={"list-item"}
            filledInfo={
              location.pathname === item.key ||
              (location.pathname === "/dashboard/licenses" &&
                item.key === "/dashboard/supervision") ||
              (location.pathname === "/dashboard/supervision" &&
                item.key === "/dashboard/licenses")
            }
            key={`list-item-${ix}`}
          >
            {typeof item === "string" ? <span>{item}</span> : item}
          </CheckListItem>
        ))}
      </ul>
    </nav>
  );
}

export default Checklist;
